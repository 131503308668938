// You can add global styles to this file, and also import other style files
// sass-lint:disable no-color-literals, force-pseudo-nesting, force-element-nesting, no-qualifying-elements

$primary: black;
$secondary: black;
@import "@angular/cdk/overlay-prebuilt.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '@ng-select/ng-select/themes/default.theme.css';
@import './assets/scss/fam-fam-flags.scss';
@import './assets/scss/variables.scss';
@import './assets/scss/datatables.scss';
@import './assets/scss/proxima-nova.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/scss/tailwind.scss';

html {
    height: 100%;
}

body {
    @apply text-black-900 text-[16px] font-medium min-h-full;
    font-family: 'proxima-nova', sans-serif;
}

// Colors
.red {
    color: $red;
}

.orange {
    color: $orange;
}

.green {
    color: $green;
}

.fullpage-background {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    .background-image,
    .background-overlay {
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .background-image {
        background-image: url('/assets/img/background.svg');
        background-position: center;
        background-size: cover;
    }

    .background-overlay {
        opacity: .8;
    }
}

.form-control[disabled] {
    background-color: #f9f9f9;
}

.select-row-limit {
    width: 75px;
}

.btn {
    border: 0;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 2em;
    min-width: 200px;
    position: relative;
    text-transform: uppercase;

    &.btn-small {
        min-width: 0;
    }

    &.btn-icon {
        line-height: unset;
        min-width: unset;
        padding: 8px;

        svg {
            margin: 0;
        }
    }

    &.btn-primary,
    &.btn-primary:not(:disabled):not(.disabled).active,
    &.btn-primary:not(:disabled):not(.disabled):active {
        background-color: $primary;
        color: $white;
    }

    &.btn-default {
        background-color: #FFFFFF;
        border: 1px solid $grey;
        color: $primary;

        &:hover {
            background-color: $light;
            color: $primary;
        }
    }

    &.btn-light {
        background-color: $light;
        border: 1px solid $grey;

        &:hover {
            background-color: #E2E6EA;
        }
    }

    .addon {
        margin-left: 5px;
        position: relative;
    }
}

@media (min-width: 576px) {
    .input-group.d-sm-block {
        display: flex !important;
    }
}

.ng-select.ng-select-single,
.ng-select.ng-select-multiple {
    .ng-select-container {
        @apply h-fit min-h-[48px];
    }
}

.ng-select {
    //display: inline !important;
    //width: 100%;
}

.ng-select-container {
    @apply border border-background;
}

.ng-select .ng-select-container {
    @apply min-w-[200px] bg-white rounded-sm border border-black-200;

    &:hover {
        @apply shadow-none;
    }

    .ng-value-container {
        .ng-placeholder,
        .placeholder {
            @apply text-black-300 text-center;
            position: unset !important;
        }
    }

    div.value,
    div.placeholder,
    div.clear,
    div.toggle {
        @apply text-brand-primary;
    }
}

.ng-select.ng-select-multiple {
    padding: 0;
    border: none;
}

ng-select.is-valid > .ng-select-container {
    border-color: #28A745 !important;
}

ng-select.is-invalid > .ng-select-container {
    border-color: #DC3545 !important;
}

.ng-select.tooltip-select {
    .ng-select-container {
        overflow: visible;
    }

    .ng-value-container {
        width: 100%;
        overflow: hidden;
    }
}

// sass-lint:enable-all

ng-select.language > div > div.single > div.placeholder {
    text-align: left;
}

.selectbox {
    min-width: 200px;
}

.module-container {
    @apply bg-background rounded-md;

    .module-image {
        @apply rounded-l-md;
    }


    .module-description {
        @apply px-4 py-2;
    }

    .module-image {
        @apply p-2 flex justify-center items-center;
    }

    textarea {
        min-height: 100px;
    }
}

.scorm-language .container {
    background-color: $light;
    border: 1px solid $grey;
    border-radius: 3px;
    padding: 20px;
}

// Buttons that look like links
.btn-link {
    color: #007BFF;
    border: 0;
    cursor: pointer;
    padding: 0;
    background-color: transparent;

    &:hover {
        color: #0056B3;
    }

    &.disabled,
    &:disabled {
        color: $grey;
    }
}

// Styles for ng-select
ng-select.ng-select .ng-overlay-container {
    height: 0;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 0;
    z-index: 1000;

    .ng-overlay {
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: auto;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
    }
}

.upload-items {
    display: block;
    width: 100%;
}

.upload-items .upload-item {
    width: 100%;
    display: block;
    margin-top: 20px;
}

.upload-items .upload-item .filename {
    display: block;
    padding: 0 0 5px 5px;
    color: #74809D;
}

.upload-items .upload-item .progress {
    display: block;
    position: relative;
    width: 100%;
    height: 15px;
    border: 1px solid #5754A3;
    border-radius: 2px;
}

.upload-items .upload-item .progress .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    background: #5754A3;
}

.start-upload-btn {
    margin-top: 10px;
}

.datatable-icon-up {
    border-color: $primary transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
}

.datatable-icon-down {
    border-color: transparent transparent $primary;
    border-style: solid;
    border-width: 0 5px 5px;
    position: relative;
    top: -2px;
}

.nav-tabs .nav-link:not(.active) {
    border-color: #E9ECEF #E9ECEF #DEE2E6;
    color: rgba($black, .4);
}

.nav-tabs .nav-link.active {
    background-color: #F8F9FA;
    border-color: rgba($black, .25);
}

.modal {
    z-index: 99999;
}

.modal-content {
    max-width: 800px;
    margin: 0 auto;
}

// angular2-notifications styles
simple-notifications .simple-notification-wrapper.top {
    top: 100px;
    z-index: 999999;
}

// Website styles
.about-container,
.contact-container {
    max-width: 1400px;
    color: white;

    h1 {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.4rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.18rem;
        margin-bottom: 1.5rem;
        text-transform: unset;
    }

    p.lead {
        font-size: 1.2rem;
        font-weight: 300;
        margin: 1rem auto 1.5rem;
    }

    .link {
        color: white;
        text-decoration: underline;

        &:hover {
            color: white;
        }
    }

    img.box-shadow {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    }

    i {
        margin: 10px 20px 10px 0;

        &.fa-phone {
            transform: scaleX(-1);
        }
    }
}

/* Sortable component */
.sortable-item {
    cursor: move;

    &:hover {
        background-color: #F9F9F9;
    }

    &:active {
        cursor: grabbing;
    }
}

.sortable-item-active {
    background-color: #E6E6E6;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.3rem;
    }
}

/* Dock to the bottom of the screen */
.dock-bottom {
    bottom: 0;
    padding-bottom: 15px;
    padding-top: 15px;
    position: fixed;
    z-index: 1;
    width: 100%;

    li.pages.active {
        a {
            text-decoration: underline
        }
    }
}

// Style for save buttons, so they don't dock to the bottom of the screen
button.save {
    margin-bottom: 20px;
}

// Disabled buttons
button:disabled, button.btn-light:disabled, button.btn:disabled {
    color: #CCCCCC;

    svg {
        color: #BBBBBB !important;
    }
}

// Button lists
ul.button-list {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
}

// Country flags
.country-flag {
    height: 16px;
    margin: 4px 0;
    box-shadow: 0 0 2px;
}

// Highlight
.highlight {
    padding: 10px;
    background-color: #F8F9FA;
}

//Use Angular Valid/Invalid style classes with Bootstrap
.ng-submitted .ng-valid,
.ng-valid.ng-dirty,
.ng-valid.ng-touched {
    @extend .is-valid;
}

.ng-submitted .ng-invalid,
.ng-invalid.ng-dirty,
.ng-invalid.ng-touched {
    @extend .is-invalid;
}

// Prevent validation on some input fields that don't need to be validated
.form-select.no-validate:valid {
    border: 1px solid #ced4da;
    background-image: none !important;
    padding-right: 0 !important;
}

// CodeMirror
.codemirror {
    font-size: 15px;
    border: 1px solid #cccccc;
}

.no-modules-available {
    color: #bbc6d3;
    font-size: 22px;
    max-width: 560px;
    margin-top: 25vh;
}

