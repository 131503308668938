/*
    Proxima Nova font

    We used to import the CSS through Typekit but came across an issue with adblockers.
    So we are now using a local file instead but still importing the font from Typekit.

    Original: @import url("https://use.typekit.net/lkv4inn.css");
*/

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/l?subset_id=2&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/d?subset_id=2&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/e3ed45/00000000000000007735e602/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/l?subset_id=2&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/d?subset_id=2&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/d83550/00000000000000007735e60a/30/a?subset_id=2&fvd=i9&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/2555e1/00000000000000007735e603/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/4de20a/00000000000000007735e604/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/l?subset_id=2&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/d?subset_id=2&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/8738d8/00000000000000007735e611/30/a?subset_id=2&fvd=n8&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/58acf5/00000000000000007735e622/30/l?subset_id=2&fvd=i8&v=3") format("woff2"),url("https://use.typekit.net/af/58acf5/00000000000000007735e622/30/d?subset_id=2&fvd=i8&v=3") format("woff"),url("https://use.typekit.net/af/58acf5/00000000000000007735e622/30/a?subset_id=2&fvd=i8&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:800;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/l?subset_id=2&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/d?subset_id=2&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/154cda/00000000000000007735e601/30/a?subset_id=2&fvd=n1&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/7283cd/00000000000000007735e608/30/l?subset_id=2&fvd=i1&v=3") format("woff2"),url("https://use.typekit.net/af/7283cd/00000000000000007735e608/30/d?subset_id=2&fvd=i1&v=3") format("woff"),url("https://use.typekit.net/af/7283cd/00000000000000007735e608/30/a?subset_id=2&fvd=i1&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?subset_id=2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?subset_id=2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/l?subset_id=2&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/d?subset_id=2&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/a?subset_id=2&fvd=i6&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?subset_id=2&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/d?subset_id=2&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/a?subset_id=2&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/40d372/00000000000000007735e607/30/l?subset_id=2&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/40d372/00000000000000007735e607/30/d?subset_id=2&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/40d372/00000000000000007735e607/30/a?subset_id=2&fvd=i3&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
    font-family:"proxima-nova";
    src:url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/l?subset_id=2&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/d?subset_id=2&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/79862c/00000000000000007735e60e/30/a?subset_id=2&fvd=i5&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }
