@import './variables.scss';

ngx-datatable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
ngx-datatable.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: none;
}

ngx-datatable.ngx-datatable .datatable-footer .datatable-pager .pager,
ngx-datatable.ngx-datatable .datatable-footer .datatable-pager .pager li {
  margin-left: 10px;
}

datatable-header-cell.datatable-header-cell.resizeable.sortable {
    white-space: pre-wrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

ngx-datatable.ngx-datatable {
    height: 100%;
    min-height: 600px;
    margin-bottom: 40px;

    [visibilityobserver] {
        @extend %flex;
        max-height: 100%;
    }

    datatable-header {
        border-bottom: 1px solid $grey;
        min-height: 40px !important;

        datatable-header-cell {
            color: $primary;
            font-size: 13px;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    datatable-body.datatable-body {
        overflow: auto;
        flex: 1;
        max-height: 100%;
        min-height: 500px;

        datatable-body-row {
            border-bottom: 1px solid $grey;
            padding-bottom: 6px;
            padding-top: 12px;

            &:hover {
                background-color: $light;
            }

            datatable-body-cell {
                color: $secondary;
                font-size: 15px;
                font-weight: 300;

                .datatable-body-cell-label {
                    .fa-times {
                        color: $red;
                    }
                }
            }
        }
    }

    datatable-footer.datatable-footer {
        z-index: 9001;
    }
}

// IE10+ styles to adapt for flexbox
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  a.navbar-brand {
    display: block;
    max-width: 200px;
  }

   ngx-datatable.ngx-datatable {
     min-height: 700px;

     datatable-body.datatable-body {
       flex: 0 0 600px;
     }

     datatable-footer.datatable-footer {
         .dock-bottom {
             z-index: 9001;
             background-color: #fff;

             .ml-auto {
                 margin-left: 350px !important;
             }
         }
     }
   }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
    ngx-datatable.ngx-datatable {
      datatable-footer.datatable-footer {
          .dock-bottom {
              z-index: 9001;
          }
      }
    }
}
