// $primary: #414042;
// $secondary: #808285;
$light: #f8f9fa;
$white: #fff;
$black: #000;
$grey: #bcbec0;
$red: #E84258;
$dark-red: #78232F;
$green: #b0d8a4;
$dark-green: #6E8766;
$orange: #FEE191;
$dark-orange: #B18845;
$blue: #0071DD;
$dark-blue: #002C4A;

$navbarHeight: 80px;

%flex {
    display: flex;
    flex: 1;
    flex-direction: column;
}

// Limit component height to window height
%window-height-host {
    @extend %flex;
    max-height: calc(100vh - #{$navbarHeight});
}

// Make content section full height
%full-height-content {
    @extend %flex;

    .container {
        @extend %flex;

        // Content row, skipping header row
        .row:last-of-type {
            flex: 1;
        }
    }
}
