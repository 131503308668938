.flex-center {
    @apply flex justify-center items-center;
}

.container {
    @apply mx-auto px-[20px];
}

.header-section {
    // sticky top-[64px] z-10
    @apply px-[20px] pb-4 pt-4 md:pt-8;
}

.heading {
    @apply font-bold text-[30px] md:text-[32px] block;
}

.form-label {
    @apply block text-[14px] font-semibold text-black-700 mb-1;
}

.form-control {
    @apply block w-full rounded-sm border border-black-200 min-h-[48px] px-[14px] py-2 leading-6 transition duration-300 placeholder:text-black-300 focus:ring-0 focus:outline-0 focus:border-brand-primary;

    &[readonly] {
        @apply bg-background border-background focus:border-background text-black-300;
    }
}

.ng-submitted .ng-valid,
.ng-valid.ng-dirty,
.ng-valid.ng-touched {
    &.form-control:not(.password) {
        background-image: url('/assets/img/check-solid.svg');
        background-position: calc(100% - 16px) 14px;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    @apply border-success;
}

.ng-submitted .ng-invalid,
.ng-invalid.ng-dirty,
.ng-invalid.ng-touched {
    @apply border-danger;

    &.form-control:not(.password) {
        background-image: url('/assets/img/x-solid.svg');
        background-position: calc(100% - 19px) 14px;
        background-repeat: no-repeat;
        background-size: 14px;
    }
}

.input-search {
    @apply pr-[40px];

    background-image: url('/assets/img/search.svg');
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
}

.form-icon-after {
    @apply cursor-pointer absolute inset-y-0 right-0 flex items-center text-black-400 pr-4;
}

.form-check {
    @apply flex items-center;

    &:has(:disabled) {
        @apply opacity-40;
    }

    .form-check-input {
        @apply h-4 w-4 rounded-sm border-black-300 text-brand-secondary focus:ring-brand-secondary focus:border-brand-secondary;

        &:checked {
            @apply border-brand-secondary;
        }
    }

    .form-check-label {
        @apply ml-[8px] block text-[14px] leading-6;
    }
}

.form-select {
    @apply border border-black-200 rounded-sm h-[46px] transition duration-300 focus:ring-0 focus:outline-0 focus:border-brand-primary;
}

.input-group {
    @apply flex items-stretch;

    .input-group-text {
        @apply bg-white inline-flex items-center font-normal border-black-200 px-3 text-black-900;
    }

     > :first-child {
         @apply rounded-l-sm border rounded-r-none;

         .ng-select-container {
             @apply rounded-l-sm;
         }
     }

     > :not(:first-child, :last-child) {
         @apply border rounded-l-none rounded-r-none;
     }

     > :last-child {
          @apply rounded-r-sm border;

         .ng-select-container {
             @apply rounded-r-sm;
         }
     }

     .ng-select .ng-select-container {
         @apply border-0;
     }
}

.invalid-feedback {
    @apply mt-1 text-[12px] text-danger;

    fa-icon {
        @apply mr-1;
    }
}

.link {
    @apply cursor-pointer font-medium transition duration-300;
}

.link-primary {
    @apply text-brand-primary hover:text-brand-primary/80 transition;
}

.button-group {
    @apply flex items-center gap-x-4;
}

.button {
    @apply flex items-center px-4 h-[44px] rounded-sm text-base font-semibold leading-6 transition duration-300;

    fa-icon {
        @apply md:mr-2;
    }
}

.button.button-full {
    @apply w-full justify-center;
}

.button.button-small {
    @apply px-1 py-0.5;
    height: unset;
}

.button.button-primary {
    @apply bg-brand-primary text-white hover:bg-brand-primary/80;
}

.button.button-secondary {
    background-color: var(--primaryButtonBackgroundColor, theme('colors.brand.secondary'));
    color: var(--primaryButtonTextColor, theme('colors.white'));

    &:hover {
        filter: grayscale(10%);
    }
}

.button.button-white {
    background-color: var(--secondaryButtonBackgroundColor, theme('colors.white'));
    color: var(--secondaryButtonTextColor, theme('colors.black.900'));

    &:hover {
        filter: grayscale(10%);
    }
}

.button.button-outline {
    @apply text-black-900 border border-black-200 bg-transparent;
}

.button.button-danger {
    @apply bg-danger text-white hover:bg-danger/80;
}

.button:disabled {
    @apply bg-black-400 text-black-300 hover:bg-black-400 cursor-not-allowed;

    svg {
        @apply text-black-200;
    }
}

.button-link {
    @apply inline-block cursor-pointer;

    &.button-delete {
        @apply text-danger;
    }

    &:not(.button-delete) {
        @apply text-brand-primary;
    }

    &.disabled {
        @apply text-black-300;
    }
}

.datatable {
    @apply w-full bg-white md:mb-4 rounded-md border-b-2 border-b-background;

    .table-wrapper {
        @apply w-full overflow-y-auto;

        max-height: calc(100vh - 340px);

        @media (min-width: 768px) {
            max-height: calc(100vh - 420px);
        }
    }

    table {
        @apply w-full relative;
    }

    th, td {
        @apply border-b-2 border-background leading-6;

        &:first-child {
            @apply pl-[22px];
        }

        &:last-child {
            @apply pr-[22px];
        }
    }

    tr:last-child td {
        @apply border-0;
    }

    thead {
        @apply sticky top-0 bg-white border-b-2 border-black;
        z-index: 5;
        box-shadow: 0 0 0 1px rgba(241, 244, 251);

        tr {
            th {
                @apply  pr-[10px] py-[16px] text-left font-medium text-black-300 text-[14px];
            }
        }
    }

    tbody {
        tr {
            td {
                @apply pr-[8px] py-[20px] text-black-900 font-medium;
            }
        }
    }

    input[type="checkbox"] {
        @apply h-4 w-4 rounded-sm border-2 border-black-200 text-brand-secondary focus:ring-brand-secondary focus:border-brand-secondary;

        &:checked {
            @apply border-brand-secondary;
        }
    }

    .pagination {
        @apply rounded-b-md border-t-2 border-background;
    }

    .button-link {
        @apply mr-[15px];

        &:last-child {
            @apply mr-0;
        }
    }
}

.user-datatable {
    .datatable .table-wrapper {
        @media (min-width: 768px) {
            max-height: calc(100vh - 500px) !important;
        }
    }
}

.breadcrumbs {
    @apply flex items-center gap-x-2;

    .button-link {
        @apply mr-0;
    }
}

// Login navbar
nav.auth {
    .nav-button {
        @apply px-3 py-2.5 rounded-sm text-black-400;
    }
}

// Sidebar styles
nav.main,
nav.sidebar {
    .nav-link {
        @apply static overflow-visible cursor-pointer flex gap-x-3 rounded-[5px] px-[14px] h-[50px] text-base leading-6 font-semibold mt-1 items-center transition;

        svg {
            @apply  block;
        }
    }
}

nav.main {
    .nav-link {
        @apply hover:bg-[#F2F2F3];

        svg {
            @apply w-[22px] h-[22px];
        }
    }

    .active-link {
        @apply bg-background text-brand-primary focus:text-brand-primary;
    }
}

nav.sidebar {
    .nav-link {
       @apply hover:bg-[#D9DEE3];

        svg {
            @apply w-[22px] h-[17px];
        }
    }

    .active-link {
        @apply bg-[#D9DEE3] text-brand-primary focus:text-brand-primary;
    }
}

.dropdown {
    @apply relative w-fit cursor-pointer;

    .dropdown-menu {
        @apply rounded-sm;

        .separator {
            @apply border-t border-t-black-100;
        }

        li {
            @apply m-1 h-auto;

            .dropdown-item {
                @apply block w-full min-w-max text-[14px] text-black-900 cursor-pointer px-2 py-2 rounded-sm hover:bg-black-100 focus:bg-black-100 transition duration-100;

                &.active {
                    @apply bg-background text-brand-primary;
                }
            }
        }
    }

    .profile {
         @apply flex justify-center items-center bg-white/30 rounded-full h-[40px] w-[40px];
    }

    button {
        @apply flex items-center;
    }

    .fa-chevron-down {
        @apply text-black-400 ml-[10px];
    }
}

.card {
    @apply bg-white shadow-none rounded-md border-0 h-full w-full;

    .card-header {
        @apply flex items-center justify-between rounded-t-md bg-white text-black-700 font-medium px-[20px] pt-[16px] pb-[14px] capitalize border-b-background;
    }

    .card-body {
        @apply p-[20px];
    }
}

// Dashboard license data
dl {
    @apply grid gap-y-3;

    dd, dt {
        @apply font-medium text-[14px];
    }
}

dl#license-data {
    div {
        @apply flex justify-between;
    }
}

dl#whitelisting-data,
dl#summary-data {
    div {
        @apply grid grid-cols-3;

        dd {
            @apply col-span-2;
        }
    }
}

dl#summary-data {
    @apply bg-background rounded-sm px-4 py-5;
}

dl#organization-data {
    dd {
        @apply text-black-900 text-[16px];
    }

    dt {
        @apply text-black-400;
    }
}

// Tabs
.tabs {
    @apply flex items-center bg-white w-fit px-[6px] py-[5px] rounded-[5px] gap-[6px] border border-black-200;

    .tab {
        @apply px-[20px] py-[5px] text-black-900 rounded-[5px] font-medium hover:bg-black-200 transition;

        &:disabled {
            @apply text-black-300;
        }

        &.active {
            @apply text-brand-primary bg-black-200;
        }
    }
}

// Tiny badge
.module-badge {
    @apply inline-flex text-black-900 text-[15px] font-medium px-[8px] py-[4px] rounded-[20px] bg-background;
}

.language-switcher {
    .tabs {
        @apply justify-around h-[48px];
        width: calc(50% - 20px);

        .tab {
            @apply flex-1 px-0;

            .country-flag {
                @apply mx-auto;
            }
        }
    }
}

// Modal
.modal-title {
    @apply font-semibold text-[18px] pr-[30px];
}

.modal-body {}

.modal-footer {}

#spinner {
    z-index: 19 !important;
}

// Codemirror editor
.codemirror,
.cm-editor,
.cm-scroller {
    @apply rounded-sm;
}

// License modules
.tag-module {
    @apply w-full flex justify-between px-5 py-1.5 bg-background rounded-sm;
    @apply cursor-pointer transition-all duration-500 ease-in-out hover:bg-brand-primary hover:text-white;

    span {
        @apply flex-1;
    }

    button {
        @apply w-[10%];
    }
}

// Program modules
.program-module {
    @apply w-full flex items-center justify-between py-1.5 bg-background;

    span {
        @apply flex-1;
    }

    button {
        @apply w-[10%];
    }
}


/** Drag & Drop styling **/
.drop-container {
    @apply bg-white rounded-md h-[150px] w-full flex items-center justify-center border border-dashed border-black-400;
    @apply font-medium mb-0 text-black-700;

    &.is-invalid {
        @apply border-danger;
    }

    .upload-button {
        @apply inline-block border-none outline-none cursor-pointer text-brand-primary text-[16px] mb-0;

        input {
            @apply hidden;
        }

    }
}

.dndDragging,
.dndDraggingSource,
.dndDragover {
    @apply cursor-grabbing pointer-events-auto;
}

// Userlane overwrites
#userlane-assistant-container > div,
#usln-p-assistant-avatar,
#usln-p-assistant-avatar > div > img,
#usln-p-assistant-avatar > div > div,
#usln-p-assistant-avatar > div {
    @apply rounded-sm;
}

#usln-p-assistant-avatar > div {
    @apply shadow-none;
}

#usln-p-assistant-avatar > div > div {
    @apply top-0;
}

.program-table-header {
    @apply pb-4 font-bold text-black-400 text-[14px] border-b border-b-background;
}

.program-table-header,
.program-package,
.program-package-module {
    display: grid;
    grid-template-columns: 1fr 1fr 10fr 4fr 2fr 2fr 2fr 2fr;
    gap: 10px;
    align-items: center;
}

.program-package {
    @apply py-2 border-b border-b-background;
}

.program-package-module {
    @apply bg-background py-2;
}

// Datepicker
.owl-dt-popup-container {
    @apply rounded-sm shadow-xl;
}

.owl-dt-calendar-control {
    @apply bg-gray-100 rounded-t-sm;
}

.owl-dt-weekdays {
    @apply bg-gray-100 font-semibold text-[16px] border-b border-b-gray-200;
}

.owl-dt-calendar-main {
    @apply p-0;
}

.owl-dt-calendar-table {
     .owl-dt-calendar-header {
         @apply text-black;

         .owl-dt-calendar-table-divider {
             display: none;
         }
     }

    .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
        @apply border border-gray-200;
    }

    .owl-dt-calendar-cell-selected {
        @apply border border-brand-primary text-black-900;
        background-color: rgba(0, 0, 0, 0.04);
    }
}

// Inline timepicker for campaign builder
.owl-dt-inline-container {
    @apply shadow-none;

    .owl-dt-timer {
        @apply space-x-4 h-0 p-0;
    }

    .owl-dt-timer-input {
        @extend .form-control;
    }
    .owl-dt-timer-divider {
        @apply -left-3;

        &::after, &::before {
            @apply w-1 h-1;
        }

        &::before {
            @apply top-14;
        }

        &::after {
            @apply -bottom-12;
        }
    }

    .owl-dt-control-arrow-button {
        @apply text-brand-primary;
    }
}
